import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Splide from '@splidejs/splide';
// import Splidevideo from '@splidejs/splide-extension-video';
import LazyLoad from "vanilla-lazyload";

// --------------------------------------------------
// 🐌 General
// --------------------------------------------------

gsap.registerPlugin(ScrollTrigger);

gsap.defaults({
  ease: 'power1.inOut',
  duration: .5
})

// --------------------------------------------------
// 💤 Lazy Loading
// --------------------------------------------------

const lazyLoadInstance = new LazyLoad({
  // Your custom settings go here
  elements_selector: "[data-lazy]",
  callback_loaded: (el) => {
    gsap.to(el, {
      autoAlpha: 1,
      duration: .5,
      ease: 'power1.inOut',
      onComplete: () => {
        el.dispatchEvent(new Event('lazyloaded'));
      }
    })
  }
});

// --------------------------------------------------
// 🎠 Carousels
// --------------------------------------------------

let carousel = document.querySelector('.splide');

let splide = new Splide(carousel, {
  type: 'loop',
  speed: 600,
  flickPower: 300,
  arrows: true,
  pagination: false,
  perPage: 2.5,
  perMove: 1,
  focus: 'center',
  width: '100%',
  updateOnMove: true
});

// carousel.addEventListener('pause', () => {
//   splide.Components.Autoplay.pause();
// })

// carousel.addEventListener('resume', () => {
//   splide.Components.Autoplay.play();
// })

// Trigger lazyloading after splide has cloned the slides
// splide.on('ready', () => {
//   setupLazyLoading(carousel);
// })

splide.mount();

// Auto height
if(carousel.classList.contains('carousel--auto-height')) {
  const autoHeight = () => {
    let activeSlide = splide.Components.Elements.slides[splide.index];
    gsap.set(carousel.querySelector('.splide__list'), {
      height: activeSlide.offsetHeight
    })
  }
  autoHeight();
  splide.on('move', (e) => {
    autoHeight();
  })
}

// Counter container
let carouselCounter = document.querySelector('.carousel-counter');
if(carouselCounter) {

  let counterContainer = document.querySelector('.carousel-counter__container');
  let counterStatuses = document.querySelectorAll('.carousel-counter__status');

  const updateCounter = () => {

    counterStatuses.forEach((counterStatus) => {
      counterStatus.innerHTML = splide.index + 1;
    })
  }

  const positionCounterContainer = () => {
    let activeSlide = splide.Components.Elements.slides[splide.index];
    let activeImageWidth = activeSlide.querySelector('img').offsetWidth;
    gsap.set(counterContainer, {
      x: activeImageWidth
    })
  }
  
  positionCounterContainer();

  splide.on('move', (e) => {
    updateCounter();
    if(counterContainer) {
      positionCounterContainer();
    }
  })
  splide.on('resize', (e) => {
    if(counterContainer) {
      positionCounterContainer();
    }
  })

}

if(carousel.classList.contains('carousel--click-to-next')) {
  let nextButtons = document.querySelectorAll('.carousel img');
  if(nextButtons) {
    nextButtons.forEach((item) => {
      item.addEventListener( 'click', function() {
        splide.go('>');
      })
    });
  };
}


// let carousels = document.querySelectorAll('.carousel');

// if (carousels.length) {

//   carousels.forEach(carousel => {
//     setupCarousel(carousel);
//   })

// }